type SourceOptions = { slug: string; label: string }[];
export const sourceOptions: SourceOptions = [
  { slug: "goodwill", label: "Goodwill" },
  { slug: "ebay", label: "Ebay" },
  { slug: "estateSale", label: "Estate Sale" },
  { slug: "craigslist", label: "Craigslist" },
];

export type NavLink = { slug: string; label: string };
export const navLinks: NavLink[] = [
  { slug: "/", label: "Dashboard" },
  { slug: "/purchases", label: "Purchases" },
  { slug: "/listings", label: "Listings" },
  { slug: "/items", label: "Items" },
];
